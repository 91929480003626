import { Breadcrumb, Card, Col, Divider, Row, Modal, message, Button } from 'antd';
import { HomeOutlined, AimOutlined } from '@ant-design/icons';
import UnitsTable from '@/components/units/UnitsTable';
import UnitsModal from '@/components/units/UnitsModal';

// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
// API
import APIService from '@/services/API';
import { Unit } from '@/interfaces/unit.interface';

const UnitsScreen = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    $skip: '0',
    $limit: '10',
  });

  const [modal, setModal] = useState({
    type: 'create',
    visible: false,
    data: {},
  });

  const { isLoading, data, refetch } = useQuery(['units', params], () =>
    APIService.getUnits(params),
  );

  const handleDelete = async (id: string) => {
    const response = await APIService.deleteUnit(id);
    if (response.ok) {
      refetch();
      message.success(t('units.deleteSuccess'));
    } else {
      message.error(response.data?.message);
    }
  };

  const onDelete = (id: string) => {
    Modal.confirm({
      title: t('units.deleteConfirmation'),
      okText: t('general.yes'),
      onOk: () => handleDelete(id),
    });
  };

  const onEdit = (data: Unit) => {
    // Hanlde edit user, open edition modal
    setModal({
      visible: true,
      type: 'edit',
      data,
    });
  };

  return (
    <div className="dashboard-screen fadeIn">
      <UnitsModal
        visible={modal.visible}
        edit={modal.type === 'edit'}
        data={modal.data}
        onOk={() => {
          refetch();
          setModal({ visible: false, type: 'create', data: {} });
        }}
        onCancel={() => {
          setModal({ visible: false, type: 'create', data: {} });
        }}
      />
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <Breadcrumb
              items={[
                {
                  href: '',
                  title: <HomeOutlined />,
                },
                {
                  href: '',
                  title: (
                    <>
                      <AimOutlined />
                      <span>{t('menu.units')}</span>
                    </>
                  ),
                },
              ]}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() =>
                setModal({
                  visible: true,
                  type: 'create',
                  data: {},
                })
              }
            >
              {t('units.create')}
            </Button>
          </Col>
        </Row>
        <Divider />
        <UnitsTable
          onFilter={setParams}
          data={data?.data}
          loading={isLoading}
          total={data?.total}
          current={(data?.skip || 0) / (data?.limit || 1)}
          {...{ onDelete, onEdit }}
        />
      </Card>
    </div>
  );
};

export default UnitsScreen;
